<template>
  <div>
    <h3>{{ $t('setting.title') }}</h3>
    <br />
    <v-divider></v-divider> <br />
    <v-row>
      <v-col>
        <v-card outlined rounded>
          <v-card-title> {{ $t('setting.props') }} </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="setting.value3"
              :label="$t('setting.orgname')"
              @change="save(3)"
              :hint="$t('setting.dochint')"
            ></v-text-field>
            <v-text-field
              v-model="setting.value7"
              :label="$t('setting.addr')"
              @change="save(7)"
              :hint="$t('setting.dochint')"
            ></v-text-field>
            <v-text-field
              v-model="setting.value4"
              :label="$t('setting.phone')"
              @change="save(4)"
              :hint="$t('setting.dochint')"
            ></v-text-field>
            <v-text-field
              v-model="setting.value5"
              :label="$t('setting.inn')"
              @change="save(5)"
              :hint="$t('setting.dochint')"
            ></v-text-field>
            <v-text-field
              v-model="setting.value6"
              :label="$t('setting.kpp')"
              @change="save(6)"
              :hint="$t('setting.dochint')"
            ></v-text-field>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col>
        <v-card outlined rounded>
          <v-card-title>{{ $t('setting.job') }}</v-card-title>
          <v-card-text>
            <v-text-field
              v-model="setting.value1"
              :label="$t('setting.jobnumtpl')"
              @change="save(1)"
              persistent-hint
              :hint="
                '[D] - ' +
                $t('setting.jobnumday') +
                ', [M] - ' +
                $t('setting.jobnummns') +
                ', [Y] - ' +
                $t('setting.jobnumyear') +
                ', [N:5] - ' +
                $t('setting.jobnumcount')
              "
            ></v-text-field>
            {{ $t('setting.jobnumexemple') }}
            <a href="#" @click="numEdit">
              <b> {{ calcval1 }} </b>
            </a>
            <br />
            <!--v-select
              v-bind:items="vals2"
              v-model="v2"
              label="Внешний вид заказов"
              bottom
              item-text="name"
              item-value="id"
            ></v-select-->
          </v-card-text>
        </v-card>
        <br />
        <v-card outlined rounded>
          <v-card-title> {{ $t('setting.moizvinki') }} </v-card-title>
          <v-card-text>
            <v-text-field
              v-model="setting.value10"
              :label="$t('setting.mzemail')"
              @change="save(10)"
            ></v-text-field>
            <v-text-field
              v-model="setting.value8"
              :label="$t('setting.mzapikey')"
              @change="save(8)"
            ></v-text-field>
            <v-text-field
              v-model="setting.value9"
              :label="$t('setting.mzurl')"
              @change="save(9)"
            ></v-text-field>
            <v-btn color="primary" @click="mzsetting">
              {{ $t('setting.mzcheck') }}
            </v-btn>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <modal_num
      v-if="modal_num"
      v-bind:view="modalView"
      v-on:close="modalNumClose"
    ></modal_num>
    <modal_mz v-if="modal_mz" v-on:close="modalMzClose"></modal_mz>
  </div>
</template>

<script>
import modal_num from './num_edit.vue'
import modal_mz from './mz_edit.vue'

export default {
  data() {
    return {
      setting: [],
      num: 1,
      modal_num: '',
      modal_mz: '',
      v2: '',
      vals2: [
        { id: 1, name: 'Плитка' },
        { id: 2, name: 'Список' },
      ],
    }
  },
  computed: {
    calcval1: function () {
      var text = this.setting.value1
      var day = new Date()
      if (text == undefined) {
        return ''
      }
      text = text.replace(/\[D\]/g, day.getUTCDate())
      text = text.replace(/\[M\]/g, day.getMonth() + 1)
      text = text.replace(/\[Y\]/g, day.getFullYear())
      text = text.replace(/\[N\]/g, this.num)
      text = text.replace(/\[N:2\]/g, this.pad(this.num, 2))
      text = text.replace(/\[N:3\]/g, this.pad(this.num, 3))
      text = text.replace(/\[N:4\]/g, this.pad(this.num, 4))
      text = text.replace(/\[N:5\]/g, this.pad(this.num, 5))
      text = text.replace(/\[N:6\]/g, this.pad(this.num, 6))
      text = text.replace(/\[N:7\]/g, this.pad(this.num, 7))
      text = text.replace(/\[N:8\]/g, this.pad(this.num, 8))
      text = text.replace(/\[N:9\]/g, this.pad(this.num, 9))
      return text
    },
  },
  components: {
    modal_num,
    modal_mz,
  },
  watch: {},
  mounted: function () {
    this.dataLoad()
  },
  methods: {
    dataLoad: function () {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'setting').then(
        (response) => {
          if (response.body.err > 0) {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
          } else {
            t.setting = response.body.setting
            console.log(t.setting)
            t.num = response.body.num
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    pad: function (c, d, b) {
      if (!b) {
        b = '0'
      }
      while (c.length < d) {
        c = b + c
      }
      return c
    },
    save: function (id) {
      var t = this
      this.$http
        .post(this.$store.state.apiUrl + 'setting/save/', {
          setting: id,
          value: this.setting['value' + id],
        })
        .then(
          (response) => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: t.$t(response.body.msg),
            })
            if (!response.body.err) {
              this.$store.dispatch('updateSetting')
              this.$emit('close', 'reload')
            }
          },
          (err) => {
            console.log(err)
          }
        )
    },
    numEdit: function () {
      //            console.log(view)
      this.modal_num = true
      this.modalView = this.num
      return false
    },
    modalNumClose: function (prm) {
      this.modal_num = ''
      if (prm > 0) {
        this.num = prm
      }
    },
    modalMzClose: function () {
      this.modal_mz = ''
    },
    mzsetting: function () {
      if (!this.setting.value8 || !this.setting.value10) {
        alert('Заполните параметры подключения')
        return
      }
      this.modal_mz = true
    },
  },
}
</script>

<style></style>
