var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h3',[_vm._v(_vm._s(_vm.$t('setting.title')))]),_c('br'),_c('v-divider'),_vm._v(" "),_c('br'),_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":"","rounded":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('setting.props'))+" ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('setting.orgname'),"hint":_vm.$t('setting.dochint')},on:{"change":function($event){return _vm.save(3)}},model:{value:(_vm.setting.value3),callback:function ($$v) {_vm.$set(_vm.setting, "value3", $$v)},expression:"setting.value3"}}),_c('v-text-field',{attrs:{"label":_vm.$t('setting.addr'),"hint":_vm.$t('setting.dochint')},on:{"change":function($event){return _vm.save(7)}},model:{value:(_vm.setting.value7),callback:function ($$v) {_vm.$set(_vm.setting, "value7", $$v)},expression:"setting.value7"}}),_c('v-text-field',{attrs:{"label":_vm.$t('setting.phone'),"hint":_vm.$t('setting.dochint')},on:{"change":function($event){return _vm.save(4)}},model:{value:(_vm.setting.value4),callback:function ($$v) {_vm.$set(_vm.setting, "value4", $$v)},expression:"setting.value4"}}),_c('v-text-field',{attrs:{"label":_vm.$t('setting.inn'),"hint":_vm.$t('setting.dochint')},on:{"change":function($event){return _vm.save(5)}},model:{value:(_vm.setting.value5),callback:function ($$v) {_vm.$set(_vm.setting, "value5", $$v)},expression:"setting.value5"}}),_c('v-text-field',{attrs:{"label":_vm.$t('setting.kpp'),"hint":_vm.$t('setting.dochint')},on:{"change":function($event){return _vm.save(6)}},model:{value:(_vm.setting.value6),callback:function ($$v) {_vm.$set(_vm.setting, "value6", $$v)},expression:"setting.value6"}})],1)],1)],1),_c('v-col',[_c('v-card',{attrs:{"outlined":"","rounded":""}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$t('setting.job')))]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('setting.jobnumtpl'),"persistent-hint":"","hint":'[D] - ' +
              _vm.$t('setting.jobnumday') +
              ', [M] - ' +
              _vm.$t('setting.jobnummns') +
              ', [Y] - ' +
              _vm.$t('setting.jobnumyear') +
              ', [N:5] - ' +
              _vm.$t('setting.jobnumcount')},on:{"change":function($event){return _vm.save(1)}},model:{value:(_vm.setting.value1),callback:function ($$v) {_vm.$set(_vm.setting, "value1", $$v)},expression:"setting.value1"}}),_vm._v(" "+_vm._s(_vm.$t('setting.jobnumexemple'))+" "),_c('a',{attrs:{"href":"#"},on:{"click":_vm.numEdit}},[_c('b',[_vm._v(" "+_vm._s(_vm.calcval1)+" ")])]),_c('br')],1)],1),_c('br'),_c('v-card',{attrs:{"outlined":"","rounded":""}},[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$t('setting.moizvinki'))+" ")]),_c('v-card-text',[_c('v-text-field',{attrs:{"label":_vm.$t('setting.mzemail')},on:{"change":function($event){return _vm.save(10)}},model:{value:(_vm.setting.value10),callback:function ($$v) {_vm.$set(_vm.setting, "value10", $$v)},expression:"setting.value10"}}),_c('v-text-field',{attrs:{"label":_vm.$t('setting.mzapikey')},on:{"change":function($event){return _vm.save(8)}},model:{value:(_vm.setting.value8),callback:function ($$v) {_vm.$set(_vm.setting, "value8", $$v)},expression:"setting.value8"}}),_c('v-text-field',{attrs:{"label":_vm.$t('setting.mzurl')},on:{"change":function($event){return _vm.save(9)}},model:{value:(_vm.setting.value9),callback:function ($$v) {_vm.$set(_vm.setting, "value9", $$v)},expression:"setting.value9"}}),_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.mzsetting}},[_vm._v(" "+_vm._s(_vm.$t('setting.mzcheck'))+" ")])],1)],1)],1)],1),(_vm.modal_num)?_c('modal_num',{attrs:{"view":_vm.modalView},on:{"close":_vm.modalNumClose}}):_vm._e(),(_vm.modal_mz)?_c('modal_mz',{on:{"close":_vm.modalMzClose}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }