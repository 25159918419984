<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="500">
    <v-card>
      <v-card-title>Проверка синхронизаци</v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        Активных подписок: {{ countList }}
        <v-btn color="primary" @click.native="subscribe">Подписаться</v-btn>
        <h3>Тестирование</h3>
        <v-text-field
          v-model="phone"
          label="Номер телефона"
          type="number"
        ></v-text-field>
        <v-btn color="primary" @click.native="call">Позвонить</v-btn>
        <v-text-field v-model="sms" label="Текст смс"></v-text-field>
        <v-btn color="primary" @click.native="smssend">Отправить SMS</v-btn>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click.native="$emit('close')">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['view'],
  data() {
    return {
      btnDel: false,
      phone: '',
      sms: '',
      dialog: true,
      valid: false,
      countList: 0,
    }
  },
  computed: {},
  mounted: function () {
    this.num = this.view
    this.list()
    //        var t = this, user=!!this.view.id?this.view.id:'' ;
  },
  methods: {
    list: function () {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'mz/list/').then(
        (response) => {
          t.countList = response.body.count
        },
        (err) => {
          console.log(err)
        }
      )
    },
    subscribe: function () {
      var t = this
      this.$http.post(this.$store.state.apiUrl + 'mz/subscribe/').then(
        (response) => {
          if (!response.body.err) {
            alert(response.body.msg)
          } else {
            t.$emit('close')
          }
        },
        (err) => {
          console.log(err)
        }
      )
    },
    call: function () {
      var t = this
      //            var arr =Object.assign({}, this.view);

      this.$http
        .post(this.$store.state.apiUrl + 'mz/call/', {
          phone: t.phone,
        })
        .then(
          (response) => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              alert(response.body.msg)
            }
          },
          (err) => {
            console.log(err)
          }
        )
    },
    smssend: function () {
      var t = this
      //            var arr =Object.assign({}, this.view);

      this.$http
        .post(this.$store.state.apiUrl + 'mz/sms/', {
          phone: t.phone,
          text: t.sms,
        })
        .then(
          (response) => {
            t.$store.dispatch('setMessage', {
              type: response.body.err == 1 ? 'error' : 'success',
              message: response.body.msg,
            })
            if (!response.body.err) {
              alert(response.body.msg)
            }
          },
          (err) => {
            console.log(err)
          }
        )
    },
  },
}
</script>

<style></style>
