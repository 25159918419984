<template>
  <v-dialog v-model="dialog" persistent scrollable max-width="500">
    <v-card>
      <v-card-title class="headline">{{ $t('setting.numtitle') }}</v-card-title>
      <v-card-text>
        <v-form v-model="valid" lazy-validation>
          <v-layout wrap>
            <v-text-field
              v-model="num"
              :label="$t('setting.numnow')"
              type="number"
            ></v-text-field>
          </v-layout>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn flat="flat" @click.native="$emit('close')">
          {{ $t('all.close') }}
        </v-btn>
        <v-btn color="primary" dark @click.native="save">
          {{ $t('all.save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['view'],
  data() {
    return {
      btnDel: false,
      num: '',
      dialog: true,
      valid: false,
    }
  },
  computed: {},
  mounted: function () {
    this.num = this.view
    //        var t = this, user=!!this.view.id?this.view.id:'' ;
  },
  methods: {
    save: function () {
      var t = this
      //            var arr =Object.assign({}, this.view);

      if (this.valid) {
        this.$http
          .post(this.$store.state.apiUrl + 'setting/numSave/', {
            num: this.num - 1,
          })
          .then(
            (response) => {
              t.$store.dispatch('setMessage', {
                type: response.body.err == 1 ? 'error' : 'success',
                message: t.$t(response.body.msg),
              })
              if (!response.body.err) {
                this.$emit('close', t.num)
              }
            },
            (err) => {
              console.log(err)
            }
          )
      }
    },
  },
}
</script>

<style></style>
